.Downloader {
  margin-top: 15vh;
}

.Downloader > p {
  margin-bottom: 12px;
}

.file-icon {
  margin-bottom: 1.5em;
}

.shared-by {
  padding-bottom: 1em;
}

.download-button {
  min-width: 260px;
}

.qr-button {
  min-width: 260px;
}

.file-qr {
  text-align: center;
  padding: 4px 0;
}