.Navbar {
  height: 44px;
}

.navbar-brand {
  height: 24px;
  padding: 0;
}


.Navbar-logo {
  border-radius: 1px;
}

.Footer {
  background-color: #fcfcfc!important;
}

.footer-text {
  color: #aaa;
  width: 100%;
  font-size: small;
  text-align: center;
}