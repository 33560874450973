.button-back {
  margin-top: 1em;
}

.login-box {
  > p {
    padding-bottom: 16px;
  }
  padding-top: 15vh;
  max-width: 350px;
}